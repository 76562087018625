import React from 'react';
import YukiMinecraftAnticheatAd from './YukiMinecraftAnticheatAd';

function App() {
  return (
    <div className="App w-full min-h-screen">
      <YukiMinecraftAnticheatAd />
    </div>
  );
}

export default App;