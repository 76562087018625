import React from 'react';
import { Snowflake, Shield, Zap, Eye, Lock } from 'lucide-react';

const YukiMinecraftAnticheatAd = () => {
  const handleButtonClick = () => {
    window.open('https://www.youtube.com/watch?v=At8v_Yc044Y', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="dark-reader-guard bg-gradient-to-br from-gray-900 to-blue-950 min-h-screen w-full p-4 sm:p-8 font-sans text-gray-100 flex flex-col justify-between">
      <header className="flex flex-col sm:flex-row items-center justify-between mb-8 sm:mb-12">
        <div className="flex items-center mb-4 sm:mb-0">
          <Snowflake className="w-8 h-8 sm:w-10 sm:h-10 text-blue-400 mr-3" />
          <div>
            <h1 className="text-2xl sm:text-3xl font-light text-white tracking-wide">
              Yuki <span className="font-normal">Anticheat</span>
            </h1>
            <p className="text-blue-300 text-xs sm:text-sm mt-1">雪 | Precision & Purity</p>
          </div>
        </div>
        <span className="px-3 py-1 sm:px-4 sm:py-2 bg-blue-800 text-white rounded-full text-xs font-medium">
          For Minecraft
        </span>
      </header>
      
      <main className="flex-grow flex flex-col justify-center items-center">
        <h2 className="text-3xl sm:text-4xl font-light mb-4 text-center text-white leading-tight">
          Redefining Minecraft Server Integrity
        </h2>
        <p className="text-center text-blue-200 mb-8 sm:mb-12 text-sm sm:text-base lg:text-lg px-4 max-w-3xl">
          <span className="inline-block mr-2">革新的技術による高度な保護</span>
          <span className="inline-block">Advanced protection through innovative technology</span>
        </p>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 mb-8 sm:mb-16 w-full max-w-5xl">
          <FeatureCard 
            icon={<Shield className="w-6 h-6 sm:w-8 sm:h-8" />}
            title="Precision Security"
            japaneseTitle="精密なセキュリティ"
            description="State-of-the-art detection algorithms ensure unparalleled protection"
          />
          <FeatureCard 
            icon={<Zap className="w-6 h-6 sm:w-8 sm:h-8" />}
            title="Optimized Performance"
            japaneseTitle="最適化されたパフォーマンス"
            description="Seamless integration with zero impact on server resources"
          />
          <FeatureCard 
            icon={<Eye className="w-6 h-6 sm:w-8 sm:h-8" />}
            title="Intelligent Monitoring"
            japaneseTitle="インテリジェントな監視"
            description="Real-time analysis and adaptive threat response"
          />
        </div>
        
        <div className="text-center mb-8 sm:mb-12 max-w-3xl">
          <p className="text-lg sm:text-xl font-light mb-6 sm:mb-8 text-white leading-relaxed px-4">
            "Yuki embodies 侘寂 (wabi-sabi): the beauty of impermanence and imperfection, 
            in a world of constant digital evolution."
          </p>
          <div className="flex items-center justify-center mb-4 sm:mb-6">
            <Lock className="w-4 h-4 sm:w-5 sm:h-5 text-blue-400 mr-2" />
            <span className="text-blue-200 text-xs sm:text-sm">
              <span className="inline-block mr-2">限定提供</span>
              <span className="inline-block">Limited Availability - Application Required</span>
            </span>
          </div>
          <button 
            onClick={handleButtonClick}
            className="px-6 sm:px-8 py-2 sm:py-3 bg-blue-600 text-white rounded-lg font-medium text-base sm:text-lg hover:bg-blue-700 transition-colors shadow-lg hover:shadow-xl transform hover:-translate-y-1"
          >
            <span className="inline-block mr-2">アクセスリクエスト</span>
            <span className="inline-block">Request Access</span>
          </button>
        </div>
      </main>
      
      <footer className="text-center text-blue-300 text-xs sm:text-sm mt-4">
        Optimized for Vanilla, Spigot, and Paper server environments
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, japaneseTitle, description }) => (
  <div className="bg-gray-800 bg-opacity-50 p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
    <div className="text-blue-400 mb-3 sm:mb-4">{icon}</div>
    <h3 className="text-base sm:text-lg font-medium mb-2 text-white">
      <span className="block">{title}</span>
      <span className="block text-sm text-blue-300">{japaneseTitle}</span>
    </h3>
    <p className="text-blue-200 text-xs sm:text-sm">{description}</p>
  </div>
);

export default YukiMinecraftAnticheatAd;